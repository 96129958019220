import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Survey = () => {
  return (
    <Layout>
      <SEO title="Peach Insurance Services LLC." />
      <div className="flex flex-col" style={{ height: 100 + 'vh' }}>
        <div className="bg-blue-800 z-0 h-24"></div>
        <iframe
          src="https://forms.office.com/Pages/ResponsePage.aspx?id=r5iQWDCyFUGnfvp31gMgGtSKg0f5OSxJuBErk_-1htZUQkhaRjNRQkJGUzVYTjQwTVZBSlQyS0FZUS4u&embed=true%22"
          frameborder="0"
          marginwidth="0"
          marginheight="0"
          className="flex-1"
          style={{
            border: 'none',
            maxWidth: 100 + '%',
            maxHeight: 100 + 'vh',
          }}
          allowfullscreen
          webkitallowfullscreen
          mozallowfullscreen
          msallowfullscreen
        ></iframe>
      </div>
    </Layout>
  );
};

export default Survey;
